<template>
  <div class="admin-talents">
    <h2>Gérer les talents</h2>
    <form class="admin-tags-filters">
      <fieldset>
        <legend>Filtre</legend>
        <div class="admin-talents-filters">
          <div>
            <label for="byJob">Filtrer par métier</label>
            <label><input type="radio" id="byJob" name="byJob" v-model="byJob" value="CREA">Créatif</label>
            <label><input type="radio" name="byJob" v-model="byJob" value="DEV">Développeur</label>
            <label><input type="radio" name="byJob" v-model="byJob" value="GES">Chef de Projet</label>
            <label><input type="radio" name="byJob" v-model="byJob" value="ADM1">Admin</label>
          </div>
          <div>
            <label for="byValidity">Masquer les talents validés</label>
            <input type="checkbox" id="byValidity" v-model="hideIfValid">
          </div>
        </div>
        <div class="admin-talents-filters">
          <div>
            <label>Filtrer par la 1ère lettre du prénom</label>
            <ul class="admin-tags-filters-letters">
              <li v-for="letter of letters" @click="byLetter=byLetter==letter?'':letter"
                  :class="letter === byLetter ? 'current' : ''">{{ letter }}
              </li>
            </ul>
          </div>
        </div>

      </fieldset>
    </form>
    <p>{{ sortedTalents.length }} talents affichés.</p>
    <table class="table table-striped table-hover table-bordered">
      <colgroup>
        <col width="20%"/>
        <col width="80%"/>
        <col/>
      </colgroup>
      <thead>
      <tr>
        <th>Talent</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="talent of sortedTalents">
        <td>
          <div class="cellProfil">
            <b>{{ talent.displayName }}</b>
            <img :src="`${config.statics}${talent.avatar}`" height="50">
          </div>
        </td>
        <td>
          <ul>
            <li>
              <router-link :to="{name: 'talent', params: {
                name: talent.screenName
              }}">Voir la page
              </router-link>
            </li>
            <li>
              <router-link :to="{name: 'admin_talents_profile', params: {
                user: talent.screenName
              }}">Modifier la fiche
              </router-link>
            </li>
            <li :class="talent.tagsV2 != 0 ? 'tagsDone' : ''">
              <a @click.prevent="editTagsLists(talent)" href="#">Editer les tags</a>
            </li>
            <li v-if="talent.tagsV2 == 1">
              <a @click.prevent="changeTagsValidation(talent, false)" href="#">Invalider les tags</a>
            </li>
            <li v-else>
              <a @click.prevent="changeTagsValidation(talent, true)" href="#">Valider les tags</a>
            </li>
          </ul>
        </td>

      </tr>
      </tbody>
    </table>
    <b-modal
        v-if="currentTalent"
        id="tagsModal"
        ok-title="Sauvegarder"
        cancel-title="Annuler"
        :hide-header-close="true"
        size="lg"
        @ok="saveTagsForCurrentTalent"
        :title="`tags de ${currentTalent.displayName}`"
    >
      <div>
        <v-select
            ref="select"
            id="searchButton"
            v-model="currentTalent.tagsOptions"
            :options="config.searchTag"
            :searchable="true"
            appendToBody="#modalTagsList"
            multiple
            placeholder="Rechercher un tag">
          <template #no-options="{ search, searching, loading }">
            <template v-if="searching">Pas de filtre supplémentaire</template>
          </template>
          <template #selected-option-container="{ option, deselect, multiple, disabled }">
            <div class="vs__selected">
              {{ option.label }}
              <component
                  class="clickable"
                  :is="crossButton"
                  @click.prevent.stop="deselect(option)"
                  width="10"
                  height="10"
              />
            </div>

          </template>
        </v-select>
        <div id="modalTagsList"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "@/helpers/api";
import {mapState} from "vuex";

export default {
  name: "setTagsForV2",
  data() {
    return {
      byJob: 'CREA',
      byLetter: '',
      hideIfValid: false,
      letters: "abcdefghijklmnopqrstuvwxyz".toUpperCase().split('')
    }
  },
  computed: {
    ...mapState(['talents', 'config', 'currentTalent']),
    filteredTalents() {
      let talents = Array.from(this.talents)
      if (this.byJob.length) {
        talents = talents.filter((talent) => {
          return talent.profilType === this.byJob
        })
      }
      if (this.byLetter.length) {
        talents = talents.filter((talent) => {
          return talent.screenName[0].toLowerCase() === this.byLetter.toLowerCase()
        })
      }
      if (this.hideIfValid) {
        talents = talents.filter((talent) => {
          return talent.tagsV2 === 0
        })
      }
      return talents
    },
    sortedTalents() {
      return this.filteredTalents.sort((talentA, talentB) => {
        if (talentA.screenName > talentB.screenName) {
          return 1
        } else {
          return -1
        }
      })
    },
    crossButton() {
      return require('@/assets/svg/croix.svg')
    }
  },
  methods: {
    getTagsList(talent) {
      let tags = talent.tags.map((tag) => {
        return this.config.tags[tag]
      })
      return tags
    },
    getTagName(tag) {
      return this.config.tags[tag] || ''
    },
    async editTagsLists(talent) {
      await this.$store.dispatch('SET_CURRENT_TALENT', talent)
      this.$bvModal.show('tagsModal')
    },
    async saveTagsForCurrentTalent() {
      let tags = this.currentTalent.tagsOptions.map((tag) => {
        return tag.code.split('_')[1]
      })
      await this.$store.dispatch('SAVE_TALENT_TAGS', {
        tags,
        talent: this.currentTalent
      })
      this.$bvModal.hide('tagsModal')
    },
    async changeTagsValidation (talent, status) {
      await this.$store.dispatch("SET_TAGS_STATUS", {
        talent,
        status
      })
    }
  }
}
</script>
<style lang="scss">
.admin-talents-filters {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
}
#modalTagsList .vs__dropdown-menu {
  position: static !important;
  display: flex !important;

  li {

  }
}

.tagsDone a {
  background-color: $pale-green;
}
.admin-tags-filters-letters {
  .current {
    background-color: $pale-green;
  }
}
</style>
