<template>
  <div class="accordionItem" :class="isOpen ? 'accordionItem-open' : 'accordionItem-close'">
    <div class="accordionItem_title" @click.prevent="open">
      <span>{{title}}</span>
      <component :is="arrow"></component>
    </div>
    <div class="accordionItem_body" v-if="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import EventBus from "@/helpers/EventBus";

export default {
  name: "AccordionItem",
  data () {
    return  {
      isOpen: null,
      uid: Date.now() * Math.random()
    }
  },
  props: {
    isDefaultOpen: {
      type: Boolean,
      default: false
    },
    group: {
      type: String,
      default: 'default'
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    arrow () {
      return require('@/assets/svg/collapse_arrow.svg')
    }
  },
  methods: {
    open () {
      if (this.isOpen) {
        this.close()
      } else {
        this.isOpen = true
        EventBus.$emit('accordion-change', this.group, this.uid)
      }
    },
    close () {
      this.isOpen = null
    }
  },
  mounted () {
    if (this.isDefaultOpen) {
      this.open()
    }
    EventBus.$on('accordion-change', (group, uid) => {
      if (this.group === group && this.uid !== uid) {
        this.close()
      }
    })
  }
}
</script>

<style lang="scss">
.accordionItem {
  -webkit-appearance: none;

  .accordionItem_title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    user-select: none;

    svg {
      transition: transform 350ms;
      will-change: transform;
    }
  }
}
.accordionItem-open {
  .accordionItem_title {
    svg {
      transform: rotate(180deg);
    }
  }
}
</style>
