<template>
  <div class="userCV">
    <form>
      <fieldset>
        <legend>CV</legend>
      </fieldset>
    </form>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "userCV",
  computed: {
    ...mapState(['adminTalent'])
  }
}
</script>
