<template>
  <div class="admin-tags">
    <h2>Editer les tags</h2>
    <form class="admin-tags-filters">
      <fieldset>
        <legend>Filtre sur les talents</legend>
        <div>
          <label for="byJob">Filtrer par métier</label>
          <select v-model="byJob" id="byJob">
            <option value="">Tous les métiers</option>
            <option value="CREA">Créatif</option>
            <option value="DEV">Développeur</option>
            <option value="GES">Chef de Projet</option>
          </select>
        </div>
        <div>
          <label for="byValidity">Masquer les talents validés</label>
          <input type="checkbox" id="byValidity" v-model="hideIfValid">
        </div>
        <div>
          <label>Filtrer par la 1ère lettre du prénom</label>
          <ul class="admin-tags-filters-letters">
            <li v-for="letter of letters" @click="byLetter=byLetter==letter?'':letter"
                :class="letter === byLetter ? 'current' : ''">{{ letter }}
            </li>
          </ul>
        </div>
      </fieldset>
    </form>
    <p>{{ sortedTalents.length }} talents affichés.</p>
    <table class="table table-striped table-hover table-bordered">
      <colgroup>
        <col width="20%"/>
        <col width="45%"/>
        <col width="35%"/>
        <col/>
      </colgroup>
      <thead>
      <tr>
        <th>Talent</th>
        <th>Tags</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="talent of sortedTalents" :class="talent.tagsV2 != 0 ? 'tagsDone' : ''">
        <td>
          <div class="cellProfil">
            <b>{{ talent.displayName }}</b>
            <img :src="`${config.statics}${talent.avatar}`" height="50">
          </div>
        </td>
        <td class="tags">
          <ul v-if="talent.tags && talent.tags.length">
            <li v-for="tag of talent.tags">
              <span>{{ getTagName(tag) }}</span>
            </li>
          </ul>
          <p v-else>Pas de tags</p>
        </td>
        <td>
          <ul>
            <li>
              <router-link :to="{name: 'talent', params: {
                name: talent.screenName
              }}">Voir la page
              </router-link>
            </li>
            <li>
              <a @click.prevent="editTagsLists(talent)" href="#">Editer les tags</a>
            </li>
            <li v-if="talent.tagsV2 == 1">
              <a @click.prevent="changeTagsValidation(talent, false)" href="#">Invalider les tags</a>
            </li>
            <li v-else>
              <a @click.prevent="changeTagsValidation(talent, true)" href="#">Valider les tags</a>
            </li>
          </ul>
        </td>

      </tr>
      </tbody>
    </table>
    <b-modal
        v-if="currentTalent"
        id="tagsModal"
        ok-title="Sauvegarder"
        cancel-title="Annuler"
        :hide-header-close="true"
        size="lg"
        @ok="saveTagsForCurrentTalent"
        :title="`tags de ${currentTalent.displayName}`"
    >
      <div>
        <v-select
            ref="select"
            id="searchButton"
            v-model="currentTalent.tagsOptions"
            :options="config.searchTag"
            :searchable="true"
            appendToBody="#modalTagsList"
            multiple
            placeholder="Rechercher un tag">
          <template #no-options="{ search, searching, loading }">
            <template v-if="searching">Pas de filtre supplémentaire</template>
          </template>
          <template #selected-option-container="{ option, deselect, multiple, disabled }">
            <div class="vs__selected">
              {{ option.label }}
              <component
                  class="clickable"
                  :is="crossButton"
                  @click.prevent.stop="deselect(option)"
                  width="10"
                  height="10"
              />
            </div>

          </template>
        </v-select>
        <div id="modalTagsList"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import api from "@/helpers/api";
import {mapState} from "vuex";

export default {
  name: "setTagsForV2",
  data() {
    return {
      byJob: '',
      byLetter: '',
      hideIfValid: false,
      letters: "abcdefghijklmnopqrstuvwxyz".toUpperCase().split('')
    }
  },
  computed: {
    ...mapState(['talents', 'config', 'currentTalent']),
    filteredTalents() {
      let talents = Array.from(this.talents)
      if (this.byJob.length) {
        talents = talents.filter((talent) => {
          return talent.profilType === this.byJob
        })
      }
      if (this.byLetter.length) {
        talents = talents.filter((talent) => {
          return talent.screenName[0].toLowerCase() === this.byLetter.toLowerCase()
        })
      }
      if (this.hideIfValid) {
        talents = talents.filter((talent) => {
          return talent.tagsV2 === 0
        })
      }
      return talents
    },
    sortedTalents() {
      return this.filteredTalents.sort((talentA, talentB) => {
        if (talentA.screenName > talentB.screenName) {
          return 1
        } else {
          return -1
        }
      })
    },
    crossButton() {
      return require('@/assets/svg/croix.svg')
    }
  },
  methods: {
    getTagsList(talent) {
      let tags = talent.tags.map((tag) => {
        return this.config.tags[tag]
      })
      return tags
    },
    getTagName(tag) {
      return this.config.tags[tag] || ''
    },
    async editTagsLists(talent) {
      await this.$store.dispatch('SET_CURRENT_TALENT', talent)
      this.$bvModal.show('tagsModal')
    },
    async saveTagsForCurrentTalent() {
      let tags = this.currentTalent.tagsOptions.map((tag) => {
        return tag.code.split('_')[1]
      })
      await this.$store.dispatch('SAVE_TALENT_TAGS', {
        tags,
        talent: this.currentTalent
      })
      this.$bvModal.hide('tagsModal')
    },
    async changeTagsValidation (talent, status) {
      await this.$store.dispatch("SET_TAGS_STATUS", {
        talent,
        status
      })
    }
  }
}
</script>
<style lang="scss">
.admin-tags {
  .table {
    width: 100%;

    tr.tagsDone {
      background-color: rgba(0, 255, 0, .3);

      &:hover {
        background-color: rgba(0, 255, 0, .4);
      }
    }

    td {
      vertical-align: middle;

      ul {
        display: flex;
        justify-content: flex-start;
      }
    }

    .tags {
      ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        li {
          margin: 5px 10px 5px 0;
          border: 2px solid black;
          border-radius: 30px;
          background-color: #fff;
          color: #000;
          font-weight: 500;
          padding: 5px 10px;

          &:empty {
            display: none;
          }
        }
      }

      p {
        margin: 0;
      }
    }
  }

  $offset: 3px;

  .admin-tags-filters-letters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid black;
    margin: 0px 0 40px;
    overflow: hidden;
    padding-top: $offset;

    li {
      cursor: pointer;
      border: 1px solid black;
      border-bottom: 0;
      border-radius: 5px 5px 0 0;
      padding: 2px 6px 4px 6px;
      margin: 0 3px $offset * -1;
      flex: 1;
      text-align: center;
      user-select: none;
      transition: transform 250ms;

      &.current {
        background-color: rgba(0, 0, 0, .15);
        transform: translateY($offset * -1);
      }
    }
  }
  .cellProfil {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin-right: 1em;
    }
  }
}
#modalTagsList .vs__dropdown-menu {
  position: static !important;
  display: flex !important;

  li {

  }
}


</style>
