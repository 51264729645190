<template>
  <div class="admin">
    <h1>{{ adminTalent ? `Bienvenue ${adminTalent.displayName}` : 'Administration' }}</h1>
    <template v-if="user.role === 'ADMIN'">
      <ul class="actionsList">
        <li title="Utilisateurs">
          <b-btn :disabled="true" :to="{name: 'admin_create_user'}" variant="light">Créer un utilisateur</b-btn>
        </li>
        <li title="Talents">
          <b-btn :to="{name: 'admin_talents'}" variant="light">Gestion des talents</b-btn>
        </li>
        <li title="La Team">
          <b-btn :disabled="true" variant="light">Gestion de The Team</b-btn>
        </li>
<!--        <li title="Mon profil">
          <b-btn :to="{name: 'admin_talents_profile', params: {user: user.screenName}}" variant="light">Profil de </b-btn>
        </li>-->
      </ul>
    </template>

    <router-view></router-view>

  </div>
</template>

<script>
import Loader from "@/components/Loader";
import {mapState} from "vuex";
import AccordionItem from "../../components/AccordionItem";

export default {
  name: "index",
  components: {AccordionItem, Loader},
  data () {
    return {
      isLoaderActive: false,
      loaderMessage: ''
    }
  },
  computed: {
    ...mapState(['secured', 'user', 'adminTalent']),
  },
  updated () {
    if (!this.secured) {
      this.$router.replace({
        name: 'connect',
        query: {
          to: this.$route.path
        },
      })
    }
  },
  mounted () {
    if (this.$route.name === "admin") {
      this.$router.replace({name: "admin_index"})
    }
  }
}
</script>

<style lang="scss">
.actionsList {
  .accordionItem {
    min-width: 620px;
    max-width: 620px;
    border-bottom: 1px solid black;
    margin: 0 auto;
  }

  .accordionItem_title {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: black;
    padding: 25px 0;
    line-height: .90;
  }

  .accordionItem_body {
    padding: 0 0px 25px;
  }
}

.admin {
  min-height: 50vh;

  > .actionsList {
    margin-bottom: 50px;
  }

  h1 {
    transform: translateY(-84px);
    margin: 0;
  }

  h2 {
    margin-bottom: 30px;
  }

  ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
  }
}

#globalLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  display: flex;

  .loader {
    margin: auto;
  }
}
</style>
