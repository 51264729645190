<template>
  <div class="admin_list">
    <p>Il y a actuellement {{talents.length}} talents sur le site.</p>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
name: "index",
  computed: {
  ...mapState(['user', 'talents'])
  }
}
</script>

<style scoped>

</style>
