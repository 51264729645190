<template>
  <div class="userPage" v-if="adminTalent">
    <router-view></router-view>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import {mapState} from "vuex";
import AccordionItem from "../../components/AccordionItem";

export default {
  name: "userPage",
  components: {AccordionItem, Loader},
  data () {
    return {
      usr: null
    }
  },
  computed: {
    ...mapState(['user', 'adminTalent'])
  },
  async beforeMount () {
    this.usr = await this.$store.dispatch('GET_USER', this.$route.params.user)
    await this.$store.commit('SET_TALENT', this.usr.data)
  }
}
</script>

<style>
.userPage {
  display: flex;
  justify-content: center;
}
</style>
