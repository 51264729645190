<template>
  <div>
    <form>
      <fieldset>
        <legend>Création d'un utilisateur</legend>
      </fieldset>
    </form>
  </div>
</template>

<script>
export default {
name: "createUser"
}
</script>

<style scoped>

</style>
