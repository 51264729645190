<template>
  <div class="userProfile">
    <div class="actionsList">
      <details>
        <summary>Voir</summary>
        <pre>{{ errors }}</pre>
      </details>
      <accordion-item title="Identité">
        <form class="adminForm" @submit.prevent="saveIdentity" ref="identity">
          <fieldset>
            <div class="adminFormLine">
              <input type="text" placeholder="Prénom" :disabled="!isAdmin" name="prenom" v-model="models.prenom">
              <input type="text" placeholder="Nom" :disabled="!isAdmin" name="nom" v-model="models.nom">
            </div>
            <div class="adminFormLine">
              <input type="text" v-model="models.adresse" name="adresse" placeholder="Adresse">
            </div>
            <div class="adminFormLine">
              <the-mask mask="#####" v-model="models.code_postal" name="code_postal"
                        placeholder="Code postal"></the-mask>
              <input type="text" v-model="models.ville" placeholder="Ville" name="ville">
            </div>
            <div class="adminFormLine">
              <input type="email" v-model="models.email" placeholder="Email" disabled name="email">
            </div>
            <div class="adminFormLine">
              <input type="hidden" v-model="models.telephone" name="mobile">
              <the-mask mask="## ## ## ## ##" v-model="models.telephone":masked="true" placeholder="Téléphone"
                        name="telephone" type="phone"></the-mask>
            </div>
            <div class="adminFormLine">
              <input type="url" v-model="models.site_web" placeholder="URL" name="web">
            </div>
            <div class="adminFormLine" v-if="errors.identity">
              <pre>{{errors.identity}}</pre>
            </div>
            <div class="adminFormLine adminFormSubmit">
              <button class="btBig" type="submit">Sauvegarder</button>
            </div>
          </fieldset>
        </form>
      </accordion-item>
      <accordion-item title="Nom unique à l'écran" v-if="isAdmin">
        <form class="adminForm" @submit.prevent="saveScreenName" ref="screenName">
          <fieldset>
            <div class="adminFormLine">
              <input type="text" v-model="models.screenName" name="screenName" placeholder="Nom dans l'url">
            </div>
            <div class="adminFormLine adminFormLine_error" >
              <p>Ce nom est déjà utilisé</p>
            </div>
            <div class="adminFormLine adminFormSubmit">
              <button class="btBig" type="submit">Sauvegarder</button>
            </div>
          </fieldset>
        </form>
      </accordion-item>
      <accordion-item title="Informations professionnelles">
        <form class="adminForm" @submit.prevent="saveProfesionnalInfo" ref="proInfo">
          <fieldset>
            <label for="statut" class="labelTitle">Votre statut</label>
            <div class="adminFormGroup adminFormSpacer">
              <label class="radioButton">
                <input type="radio" name="statut" id="statut" v-model="models.statut" value="agessa">
                <span>Artiste / Auteur</span>
              </label>
              <label class="radioButton">
                <input type="radio" name="statut" v-model="models.statut" value="entreprise">
                <span>Entreprise</span>
              </label>
              <label class="radioButton">
                <input type="radio" name="statut" v-model="models.statut" value="AE">
                <span>Auto-Entrepreneur</span>
              </label>
            </div>
            <template v-if="models.statut !== 'agessa'">
              <label for="siret" class="labelTitle">Numéro de SIRET</label>
              <div class="adminFormLine adminFormSpacer">
                <the-mask mask="### ### ### #####" id="siret" name="siret" v-model="models.siret"
                          placeholder="SIRET"></the-mask>
              </div>
              <label for="tva" class="labelTitle">Numéro de TVA</label>
              <div class="adminFormLine adminFormSpacer">
                <the-mask mask="FR ### ### ###" id="tva" name="tva" v-model="models.tva" placeholder="TVA"></the-mask>
              </div>
            </template>
            <template v-if="models.statut === 'agessa'">
              <label for="siret" class="labelTitle">Numéro de SIRET</label>
              <div class="adminFormLine adminFormSpacer">
                <the-mask mask="### ### ### #####" id="siret" name="siret" v-model="models.siret"
                          placeholder="SIRET"></the-mask>
              </div>

              <label for="mda" class="labelTitle">Numéro MDA</label>
              <div class="adminFormLine adminFormSpacer">
                <input type="text" id="mda" v-model="models.mda" name="mda" placeholder="N° MDA">
              </div>
            </template>


            <div class="adminFormLine">
              <div class="uploadButton">
                <span>RIB</span>
                <a :href="getPath(models.rib)" class="link" target="_blank" v-if="models.rib">Voir le document
                  enregistré.</a>
                <label>
                  Choisir un fichier
                  <input type="file" name="rib">
                </label>
              </div>
            </div>
            <div class="adminFormLine" v-if="models.statut === 'agessa'">
              <div class="uploadButton">
              <span>
                Attestation de filiation MDA
              </span>
                <a :href="getPath(models.attestation)" class="link" target="_blank" v-if="models.attestation">Voir le
                  document enregistré.</a>
                <label>
                  Choisir un fichier
                  <input type="file" name="kbis">
                </label>
              </div>
            </div>
            <div class="adminFormLine">
              <div class="uploadButton">
              <span>
                Avis INSEE ou extrait KBIS
                <small>(daté de moins de 6 mois)</small>
              </span>
                <a :href="getPath(models.kbis)" class="link" target="_blank" v-if="models.kbis">Voir le document
                  enregistré.</a>
                <label>
                  Choisir un fichier
                  <input type="file" name="kbis">
                </label>
              </div>
            </div>
            <div class="adminFormLine">
              <div class="uploadButton">
              <span>
                Attestation de vigilance <small>(de l’année en cours délivrée par l’urssaf)</small>
              </span>
                <a :href="getPath(models.vigilance)" class="link" target="_blank" v-if="models.vigilance">Voir le
                  document
                  enregistré.</a>
                <label>
                  Choisir un fichier
                  <input type="file" name="vigilance">
                </label>
              </div>
            </div>
            <div class="adminFormLine">
              <div class="uploadButton">
              <span>
                Attestation de régularité fiscale <small>(de l’année en cours)</small>
              </span>
                <a :href="getPath(models.attestation)" class="link" target="_blank" v-if="models.attestation">Voir le
                  document enregistré.</a>
                <label>
                  Choisir un fichier
                  <input type="file" name="attestation">
                </label>
              </div>
            </div>
            <div class="adminFormLine">
              <div class="uploadButton">
              <span>
                Attestation d'assurance<small>(de l’année en cours)</small>
              </span>
                <a :href="getPath(models.assurances)" class="link" target="_blank" v-if="models.assurances">Voir le
                  document enregistré.</a>
                <label>
                  Choisir un fichier
                  <input type="file" name="assurances">
                </label>
              </div>
            </div>
            <div class="adminFormLine adminFormSubmit">
              <button class="btBig" type="submit">Sauvegarder</button>
            </div>
          </fieldset>
        </form>
      </accordion-item>
      <accordion-item title="Description Rapide">

        <form class="adminForm" @submit.prevent="saveDescription" ref="desc">
          <fieldset>
            <p>Ces informations seront reprises lors de l’envoi de votre fiche via email.</p>
            <label for="xp" class="labelTitle">Expérience</label>
            <div class="adminFormLine adminFormSpacer">
              <textarea id="xp" v-model="models.exps" rows="4" name="exps"></textarea>
            </div>
            <label for="missions" class="labelTitle">Missions réalisées pour:</label>
            <div class="adminFormLine adminFormSpacer">
              <textarea id="missions" v-model="models.missions" rows="4" name="missions"></textarea>
            </div>
            <label for="diff" class="labelTitle">Ce qui fait ta différence</label>
            <div class="adminFormLine adminFormSpacer">
              <textarea id="diff" v-model="models.difference" rows="4" name="difference"></textarea>
            </div>
            <div class="adminFormLine adminFormSubmit">
              <button class="btBig" type="submit">Sauvegarder</button>
            </div>
          </fieldset>
        </form>

      </accordion-item>
      <accordion-item title="Expériences"></accordion-item>
      <accordion-item title="Formations"></accordion-item>
      <accordion-item title="Langues"></accordion-item>
      <accordion-item title="Centre d'intérêt"></accordion-item>
    </div>
  </div>
</template>

<script>
import AccordionItem from "@/components/AccordionItem";
import {TheMask} from 'vue-the-mask'
import {mapState} from "vuex";
import Index from "./index";

export default {
  name: "userProfile",
  components: {Index, AccordionItem, TheMask},
  data () {
    return {
      models: {},
      errors: {}
    }
  },
  computed: {
    ...mapState(['adminTalent', 'config', 'user']),
    isAdmin () {
      return this.user.role === "ADMIN"
    },
  },
  methods: {
    async updateUser (event) {
      let formData = new FormData(event.target);
      await this.$store.dispatch('UPDATE_USER', {
        user: this.adminTalent.screenName,
        data: formData
      })
    },
    async saveIdentity () {
      let form = this.$refs.identity
      this.setFormStatus(form, true)
      let formData = Object.fromEntries(new FormData(this.$refs.identity));
      await this.$store.dispatch('UPDATE_USER', {
        user: this.adminTalent.screenName,
        data: formData,
        action: 'identity'
      }).catch((err) => {
        this.showError(form, err.sql)
      })
      this.setFormStatus(form, false)
    },
    async saveDescription () {
      this.setFormStatus(this.$refs.desc, true)
      let formData = Object.fromEntries(new FormData(this.$refs.desc));
      await this.$store.dispatch('UPDATE_USER', {
        user: this.adminTalent.screenName,
        data: formData,
        action: 'description'
      })
      this.setFormStatus(this.$refs.desc, false)
    },
    async saveProfesionnalInfo () {
      let form = this.$refs.proInfo
      this.setFormStatus(form, true)
      let formData = new FormData(form);
      await this.$store.dispatch('UPDATE_USER', {
        user: this.adminTalent.screenName,
        data: formData,
        action: 'professional-informations'
      })
          .then((req) => {
            /*if (req) {
              // do nothing
            }*/
            this.setFormStatus(form, false)
          })
    },
    async saveScreenName () {
      let form = this.$refs.screenName
      this.setFormStatus(form, true)
      let formData = Object.fromEntries(new FormData(form));
      await this.$store.dispatch('UPDATE_USER', {
        user: this.adminTalent.screenName,
        data: formData,
        action: 'screenName'
      }).catch((err) => {
        this.showError(form, err.sql)

      })
      this.setFormStatus(form, false)
    },
    getPath (file) {
      return `${this.config.statics}${file}`
    },
    setModels () {
      this.$nextTick(() => {
        this.models = JSON.parse(JSON.stringify(this.adminTalent))
      })
    },
    setFormStatus (form, isLoading) {
      if (isLoading) {
        form.classList.add('loading')
        form.classList.remove('adminForm_error')
      } else {
        form.classList.remove('loading')
      }
    },
    showError (form, errorMap, inError = true) {
      if (inError) {
        form.classList.add('adminForm_error')
      } else {
        form.classList.remove('adminForm_error')
      }
    }
  },
  watch: {
    adminTalent: {
      immediate: true,
      handler () {
        this.setModels()
      }
    }
  },
  beforeRouteUpdate () {
    this.setModels()
  },
  mounted () {
    this.setModels()
  }
}
</script>
